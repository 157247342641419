import "./App.css";
import Card from "@mui/material/Card";
import React, { useEffect, useState, Fragment } from "react";
import axios from "axios";
import Lottie from "lottie-react";
import loadingSplashAnimation from "./loadingSplash.json";
import notFound from "./404.json";
import logo from "./logo.png";
import appstore from "./DownloadAppStore.png";
import playstore from "./DownloadPlayStore.png";
import { Link } from "react-router-dom";
import profilePicDefault from "./profilePicDefault.png";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Snackbar, Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

const SERVER_API_ADDRESS = "https://api.techcards.in/";
const LOCAL_API_ADDRESS = "http://127.0.0.1:8000/";
const PRODUCTION = true;

function Review() {
  const [alert, setAlert] = useState(false);
  const [value, setValue] = useState(3.0);
  const [review, setReview] = useState("");

  const [searchParams] = useSearchParams();
  //http://localhost:3000/review/?uid=aomhcw&title=Pania&url=https://g.page/r/CaAg76W1jnwTEAE/review
  const uid = searchParams.get("uid");
  const title = searchParams.get("title");
  const url = searchParams.get("url");

  function save_review() {
    axios.post(
      PRODUCTION
        ? SERVER_API_ADDRESS + "save_review"
        : LOCAL_API_ADDRESS + "save_review",
      JSON.stringify({
        rating: value,
        review: review,
        name: title,
        uid: uid,
      })
    );
    window.open("about:blank", "_self");
    window.close();
  }

  return (
    <div className="App">
      <div
        style={{
          height: "100vh",
          width: "98vw",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          backgroundColor: "black",
        }}
      >
        <a
          href={"https://www.techcards.in"}
          style={{
            padding: "3px 10px 3px 10px",
            margin: "10px 10px 3px 10px",
            backgroundColor: "rgba(0,0,0,0.6)",
            fontSize: "20px",
            fontWeight: "bold",
            textDecoration: "none",
            color: "#22CE83",
            borderRadius: "15px",
            position: "fixed",
            top: 0,
            left: 0,
          }}
        >
          Visit Site
        </a>
        <div style={{ marginTop: "100px" }}>
          <Grid container spacing={0} style={{ width: "100%" }}>
            <Grid item sm={1} md={2} lg={3}></Grid>
            <Grid item sm={10} md={8} lg={6}>
              <div style={{ fontSize: "30px", marginBottom: "50px" }}>
                Write a review for {title}
              </div>
              <Rating
                style={{ transform: "scale(3)" }}
                name="simple-controlled"
                value={value}
                onChange={(event, newValue) => {
                  setValue(newValue);
                  if (newValue > 3) {
                    window.open(url, "_blank");
                  }
                }}
              />{" "}
              <TextField
                style={{
                  border: "2px solid #22CE83",
                  borderRadius: "15px",
                  marginTop: "50px",
                }}
                sx={{
                  "& fieldset": { border: "none", top: 0 },
                  "& legend": { display: "none" },
                }}
                inputProps={{ style: { color: "black" } }}
                shrink="false"
                id="outlined-read-only-input"
                fullWidth
                placeholder="Review and Feedback"
                multiline
                variant="outlined"
                value={review}
                onChange={(e) => setReview(e.target.value)}
                rows={6}
              />
              <Button
                onClick={() => save_review()}
                style={{
                  marginBottom: "100px",
                  marginTop: "50px",
                  height: "40px",
                  backgroundColor: "#22CE83",
                  color: "black",
                }}
                type="submit"
                variant="contained"
              >
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    width: "200px",
                  }}
                >
                  SUBMIT REVIEW
                </span>
              </Button>
            </Grid>
            <Grid item sm={1} md={2} lg={3}></Grid>
          </Grid>
        </div>

        <a
          target="_blank"
          href="https://apps.apple.com/fi/app/techcards/id6479240275"
          style={{
            paddingLeft: "5px",
            paddingRight: "5px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
            marginTop: "-45px",
          }}
          rel="noreferrer"
        >
          <img
            style={{
              objectFit: "cover",
              height: "60px",
            }}
            src={appstore}
          />
        </a>
        <a
          target="_blank"
          href="https://play.google.com/store/apps/details?id=in.techcards.api"
          style={{
            paddingLeft: "5px",
            paddingRight: "5px",
            marginBottom: "25px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
            marginTop: "-15px",
          }}
          rel="noreferrer"
        >
          <img
            style={{
              objectFit: "cover",
              height: "60px",
            }}
            src={playstore}
          />
        </a>
        {alert ? (
          <Snackbar
            open={alert}
            autoHideDuration={3000}
            onClose={() => setAlert(false)}
            message="Content copied to Clipboard."
          />
        ) : (
          <Fragment></Fragment>
        )}
      </div>
    </div>
  );
}

export default Review;
