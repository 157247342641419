import React from "react";
import { Router, Route, Routes, useNavigate } from "react-router-dom";
import Review from "./Review";
import Profile from "./Profile";
import { Helmet } from "react-helmet";

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>Techcards</title>
        <meta name="description" content="#1 Digital marketing company" />
        <meta name="keywords" content="Digital Marketing" />
      </Helmet>
      <Routes>
        <Route exact path="/review" element={<Review />} />
        <Route exact path="*" element={<Profile />} />
      </Routes>
    </div>
  );
}

export default App;
